
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'

@Component({
  name: 'AllClients'
})

export default class AllClients extends Vue {
  search = ''
}
